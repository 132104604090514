/* eslint-disable @typescript-eslint/no-explicit-any */
import { Popconfirm, Space } from "antd";
import React, { FC, useState } from "react";
import { moreImgPath } from "../../utils/images";
import { setStorage } from "../../utils/storage";

interface Props {
    data: any,
    getMessage(chatId: number): void,
    handleDeleteConversations(): void,
    //handleNewChat(): void
}

const Chatlist: FC<Props> = ({
    data,
    getMessage,
    handleDeleteConversations,
    //handleNewChat
}) => {
    const [selectedMessageId, setSelectedMessageId] = useState('' as any);

    const groupByDate = data.reduce((group: any, chat: any) => {
        const { chatCreatedDate } = chat;
        group[chatCreatedDate] = group[chatCreatedDate] ?? [];
        group[chatCreatedDate].push(chat);
        return group;
    }, {});

    return (
        <>
            <div className="modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="chat-header">
                        <div className="msg-search">
                            <form className="d-flex">
                                <Space>
                                    <span>Chat History</span>                             
                                    <Popconfirm
                                        title="Delete the conversations"
                                        description="Are you sure to clear all conversations?"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={handleDeleteConversations}>
                                <button className="add search-option" type="button" id="more-icon"><img className="img-fluid" src={moreImgPath} alt="add" /></button>
                                </Popconfirm>
                                </Space>
                            </form>
                        </div>
                    </div>

                    <div className="modal-body">
                    <div className="chat-lists">
                      <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="Open" role="tabpanel" aria-labelledby="Open-tab">
                                    {
                                        Object.entries(groupByDate)?.map((item: any) => {
                                            return (<>
                                                <h1 className="today">{item[0]}</h1>
                                                {
                                                    item?.[1]?.map((subitem: any, index: any) => {
                                                        return (
                                                            <div className={selectedMessageId === subitem?.id ? 'chat-list p-2 active-chat' : 'chat-list p-0'} key={'chatitem_' + index}>
                                                                <Space>
                                                                <a style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: selectedMessageId === subitem?.id ? 'bold' : 'normal'
                                                                }} onClick={() => {
                                                                    getMessage(subitem.id);
                                                                    setSelectedMessageId(subitem.id);
                                                                    setStorage('chatId', subitem.id);
                                                                }} className="d-flex align-items-center">
                                                                    {subitem.title}
                                                                </a>
                                                                </Space>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                            )
                                        })
                                    }
                                </div>
                        <div className="tab-pane fade" id="Closed" role="tabpanel" aria-labelledby="Closed-tab">
                          <div className="chat-list">
                            <a href="#" className="d-flex align-items-center">
                              Chat title given by the chatbot
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </>
    )

}

export default Chatlist;
