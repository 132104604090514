/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint: Missing "key" prop for element in iterator  react/jsx-key */
import { Button, Col, Dropdown, Form, Image, Input, MenuProps, Popover, Row, Skeleton, Space, Spin, Table, Tooltip, message } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CloseSquareOutlined, ReloadOutlined } from '@ant-design/icons';
import axios from "axios";
import copy from "copy-to-clipboard";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import ReactMarkdown from "react-markdown";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
    EmailIcon,
    EmailShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import Loader from "../components/loader/Loader";
import ShareIcon from "../images/Share.svg";
import BlankChatIcon from "../images/blank_chat.svg";
import vs_logo from "../images/vs-logo.svg";
import { headers } from "../utils/axios";
import { LAWBUDDY_API_URL, VS_DEFAULT_ORG_IMAGE, VS_PORTAL_API_URL } from "../utils/constants";
import { getIframeSessionVariable, getUserStorageType, getUserToken, isMobileBrowser, setBusinessData, setCurrentUserData } from "../utils/global";
import { CopyIcon, helpfulWhiteImgPath, likeImgPath, micImgPath, noteSmallImgPath, noteWhiteImgPath, playImgPath, poweredByVoiceSphereImgPath } from "../utils/images";
import socket from "../utils/socket";
import { getStorage, removeStorage, setStorage } from "../utils/storage";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import AdobeViewer from "../AdobeViewer";
import { defaultStyles } from "../utils/defaultStyles";
import Chatlist from "../components/chatlist/Chatlist";

const IframeChat = () => {

    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

    const navigate = useNavigate();

    const routeParams = useParams();

    const inputRef = useRef(null) as any

    const bottomRef = useRef(null) as any;

    const [loading, setLoading] = useState(false as boolean);

    const [pdfViewerLoading, setPdfViewerLoading] = useState(false as boolean);

    const [messageLoading, setMessageLoading] = useState(false as boolean);

    const [chatId, setChatId] = useState(getStorage(`${getIframeSessionVariable()}chatId`) || '' as any);

    const [chatInitiated, setChatInitiated] = useState(false as boolean);

    const [isOutsideContext, setIsOutsideContext] = useState(false as any);

    const [sharedText, setSharedText] = useState('');

    const collectionId = routeParams.collectionId || getStorage(`${getUserStorageType()}collectionId`);

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const containerRef: any = useRef(null);

    const [referenceDocumentId, setReferenceDocumentId] = useState('') as any;

    const [referencePageNumber, setReferencePageNumber] = useState('') as any;

    const documentId = Number(queryParams.get('documentId'));

    const styleId = Number(queryParams.get('styleId')) || '';

    const emailId = queryParams.get('email') || '';

    const [isFromIframe, setIsFromIframe] = useState(false);

    const [messageList, setMessageList] = useState([] as string[]);

    const businessImageUrl = getStorage(`${getUserStorageType()}businessImageUrl`) || '';

    const [autoScroll, setAutoScroll] = useState(true);

    const customIsOutsideContext = queryParams.get('customIsOutsideContext') == 'true';

    const [customStyles, setCustomStyles] = useState(defaultStyles as any);

    const [suggestionQuestionList, setSuggestionQuestionList] = useState([] as string[]);

    const [isAudioRecordedCompleted, setIsAudioRecordedCompleted] = useState(false as boolean);

    const [streamingMessage, setStreamingMessage] = useState([] as string[])

    const [messageNotes, setMessageNotes] = useState("" as string)

    const [saveMessageLoading, setSaveMessageLoading] = useState(false as boolean);

    const [messageSent, setMessageSent] = useState(false);

    const [loadIframe, setLoadIframe] = useState(false);

    const [showDocumentContainer, setShowDocumentContainer] = useState(false);

    const [windowHeight, setWindowHeight] = useState('');

    const [tableHeight, setTableHeight] = useState('');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pdfDocumentResponse, setPdfDocumentResponse] = useState({} as any);

    const businessName = getStorage(`${getUserStorageType()}businessName`) as any;

    const [showBusinessName, setShowBusinessName] = useState(true);

    const [showBusinessLogo, setShowBusinessLogo] = useState(true);

    const [showPoweredBy, setshowPoweredBy] = useState(true);

    const [showWelcomeToOrganization, setshowWelcomeToOrganization] = useState(true);

    const [chatList, setChatList] = useState([] as string[]);

    const [showMessageCounter, setShowMessageCounter] = useState(false);

    const [messsageCount, setMesssageCount] = useState(0);

    const recorder = useAudioRecorder();

    const imageUrls: any = getStorage(`${getUserStorageType()}imageUrls`) != undefined ? JSON.parse(getStorage(`${getUserStorageType()}imageUrls`) as any) : [];

    const defaultImage = imageUrls?.length ? imageUrls?.[0] : VS_DEFAULT_ORG_IMAGE;

    const [profileImage, setProfileImage] = useState(defaultImage)

    const [documentsList, setDocumentsList] = useState([] as any);

    const chatContainerHeight = !getStorage('isFromRouter') ? 150 : 76;
    const tableContainerHeight = !getStorage('isFromRouter') ? 20 : 56;

    const footerClassName = !getStorage('isFromRouter') ? chatId ? `footer send-box sticky-footer-full-width-chat` : 'footer send-box sticky-footer-full-width-no-chat' : 'footer send-box sticky-footer'

    const imageStyle = {
        width: '50px',
        height: 'auto',
    };

    const altText = businessImageUrl ? "" : businessName?.charAt(0)?.toUpperCase();

    const sendMessage = (defaultQuestion?: any) => {
        setMessageSent(true);
        if (!chatId) {
            createChat(true, undefined, defaultQuestion)
        } else {
            if (formData.message || defaultQuestion) {
                createMessages(undefined, defaultQuestion)
            }
        }
    }


    const [formData, setFormData] = useState({
        message: '' as string,
        error: '' as string
    } as any)

    const onChange = (field: string, value: any) => {
        formData[field] = value;
        setFormData({ ...formData })
    }


    const createChat = (isFromSend?: boolean, recordingBlob?: any, defaultQuestion?: any) => {
        setLoading(true)
        const payLoad = {
            'collectionId': collectionId,
            'title': 'New Chat'
        } as any
        if (documentId) {
            payLoad.documentId = documentId
        }
        if (customIsOutsideContext) {
            payLoad.isOutsideContext = true;
        }
        axios
            .post(`${VS_PORTAL_API_URL}/chats`, payLoad, { headers: headers() })
            .then(async (response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    setChatId(response.data.objects.id)
                    setStorage(`${getIframeSessionVariable()}chatId`, response.data.objects.id);
                    socket.emit("joinChat", response.data.objects.id);
                    if (isFromSend) {
                        createMessages(response.data.objects.id, defaultQuestion);
                    }
                    if (recordingBlob) {
                        uploadAudio(recordingBlob, response.data.objects.id)
                    }
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const createMessages = (createdChatId?: string, defaultQuestion?: string) => {
        const currentChatId = chatId || createdChatId;
        setLoading(true)
        const payLoad = {
            'message': formData.message || defaultQuestion
        }
        axios
            .post(`${VS_PORTAL_API_URL}/messages/chats/${currentChatId}`, payLoad, { headers: headers() })
            .then((response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    if (socket) {
                        socket.emit("sendMessage", response.data.objects.id);
                    }
                    onChange('message', '')
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const userCallback = () => {
        setCurrentUserData();
        handleNewChat();
        setIsFromIframe(true);
        setLoadIframe(false);
    }

    const handleAnonymousLogin = (customStyleId: any) => {
        setSuggestionQuestionList([])
        setMessageList([]);
        sessionStorage.clear();
        setLoading(true)
        sessionStorage.setItem('isFromIFrameSession', 'true')
        const payLoad = {
            'collectionId': collectionId,
            'email': emailId ? emailId : !localStorage.getItem('anonymousEmail') ? `anon_${collectionId}@example.com` : localStorage.getItem('anonymousEmail')
        }
        axios
            .post(`${VS_PORTAL_API_URL}/users/anonymous_login`, payLoad)
            .then((response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    setStorage(`iframeRouterUserId`, response.data.objects.id);
                    setStorage(`iframeRouterCollectionId`, collectionId);
                    setStorage(`${getIframeSessionVariable()}token`, response.data.objects.token);
                    setStorage(`${getIframeSessionVariable()}refreshToken`, response.data.objects.refreshToken);
                    setStorage(`${getIframeSessionVariable()}userId`, response.data.objects.id);
                    if (!localStorage.getItem('anonymousEmail') || (localStorage.getItem('anonymousEmail') != emailId)) {
                        localStorage.setItem('anonymousEmail', emailId ? emailId : `anon_${collectionId}@example.com`)
                    }
                    localStorage.setItem('styleId', customStyleId);
                    if (!customStyleId) {
                        setStorage(`${getIframeSessionVariable()}showMessageCounter`, false);
                        setStorage(`${getIframeSessionVariable()}showBusinessName`, true);
                        setStorage(`${getIframeSessionVariable()}showBusinessLogo`, true);
                        setStorage(`${getIframeSessionVariable()}showPoweredBy`, true);
                    }
                    setTimeout(() => userCallback(), 500);
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                    setLoadIframe(false);
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)

                    setLoadIframe(false);
                }
            });

    }

    const getProfileImageUrls = () => {
        return getStorage(`${getUserStorageType()}imageUrls`);
    }

    useEffect(() => {
        const imageUrls: any = JSON.parse(getProfileImageUrls() as any);
        if (imageUrls?.length) {
            setProfileImage(imageUrls[0])
        }

    }, []);

    useEffect(() => {
        if (referenceDocumentId) {
            getPDFDocument();
            setShowDocumentContainer(true)
        } else {
            setShowDocumentContainer(false)
        }
    }, [referenceDocumentId]);


    useEffect(() => {
        if (location.hash) {
            const locationHashSplit = location.hash.split('#')
            const paramIdSplit = locationHashSplit[1].split("***");
            setReferenceDocumentId(paramIdSplit[0]);
            setReferencePageNumber(paramIdSplit[1]);
        } else {
            setReferenceDocumentId('')
            setReferencePageNumber('')
        }
    }, [location.hash]);


    useEffect(() => {
        if (chatId && getUserToken() !== null && getUserToken() !== undefined) {
            getMessagesList(chatId);
        }
    }, [chatId]);

    useEffect(() => {
        const appStyleId = localStorage.getItem('styleId');
        if (appStyleId != '' && appStyleId != null && appStyleId != undefined) {
            getCustomStyles(appStyleId)
        }
    }, [localStorage.getItem('styleId')]);

    window.onresize = function () {
        setWindowHeight((window.innerHeight - chatContainerHeight) + 'px')
        setTableHeight((window.innerHeight - tableContainerHeight) + 'px')
    };

    window.onload = function () {
        setWindowHeight((window.innerHeight - chatContainerHeight) + 'px')
        setTableHeight((window.innerHeight - tableContainerHeight) + 'px')
    };

    useEffect(() => {
        if (isFromIframe && chatId) {
            setIsFromIframe(false)
            setLoading(false)
            if (documentId > 0) {
                localStorage.setItem('parentDocumentId', `${documentId}`)
                window.location.href = `${window.location.origin}/embed_doc/${collectionId}?customIsOutsideContext=${customIsOutsideContext}#${documentId}***1`;
            } else {
                window.location.href = `${window.location.origin}/embed_doc/${collectionId}?customIsOutsideContext=${customIsOutsideContext}`;
            }
        }
    }, [isFromIframe, chatId]);

    useEffect(() => {
        if ((!!collectionId && getUserToken() !== null && getUserToken() !== undefined)) {
            setChatId('');
            setTimeout(() => getSuggestionQuestionsList(), 500);
            getChatList();
        }
    }, [collectionId, getUserToken()]);

    useEffect(() => {
        if (getStorage('newChatInitiated')) {
            setChatInitiated(!chatInitiated)
            removeStorage('newChatInitiated');
        }
    }, [getStorage('newChatInitiated')]);


    useEffect(() => {
        if (getStorage(`${getIframeSessionVariable()}chatId`)) {
            setChatId(getStorage(`${getIframeSessionVariable()}chatId`))
            socket.emit("joinChat", getStorage(`${getIframeSessionVariable()}chatId`));
        }
    }, [getStorage(`${getIframeSessionVariable()}chatId`)]);

    useEffect(() => {
        setShowMessageCounter(getStorage(`${getIframeSessionVariable()}showMessageCounter`) === 'true')
        setShowBusinessName(getStorage(`${getIframeSessionVariable()}showBusinessName`) === 'true')
        setShowBusinessLogo(getStorage(`${getIframeSessionVariable()}showBusinessLogo`) === 'true')
        setshowPoweredBy(getStorage(`${getIframeSessionVariable()}showPoweredBy`) === 'true')
        setshowWelcomeToOrganization(getStorage(`${getIframeSessionVariable()}showWelcomeToOrganization`) === 'true')
    }, [getStorage(`${getIframeSessionVariable()}showMessageCounter`),
    getStorage(`${getIframeSessionVariable()}showBusinessName`),
    getStorage(`${getIframeSessionVariable()}showBusinessLogo`),
    getStorage(`${getIframeSessionVariable()}showPoweredBy`)

    ]);

    useEffect(() => {
        if (getUserToken() !== null && getUserToken() !== undefined) {
            socket.off("receiveMessage").on("receiveMessage", (data) => {
                if (data && !!data.id) {
                    setMessageList((list) => [...list, { ...data }].sort(function (a, b) { return b.id - a.id }));
                }
            });


            socket.on('receiveMessageStream', (streamingData) => {
                setStreamingMessage((list) => [...list, ...streamingData.message])
            });

            socket.off("receiveChatState").on("receiveChatState", handleReceiveChatState)
            socket.off("receiveMessageStreamEnd").on("receiveMessageStreamEnd", handleReceiveMessageStreamEnd)
            socket.on('receiveTotalMessages', (messsageCountResponse) => {
                setMesssageCount(messsageCountResponse?.countByDay)
            });
        }

    }, [socket]);



    const handleReceiveChatState = (data: any) => {
        if (data.state === 'writing') {
            setMessageLoading(true)
        } else {
            setMessageLoading(false)
        }
    }

    const handleReceiveMessageStreamEnd = () => {
        setStreamingMessage([])
    }

    const handleNewChat = () => {
        setChatInitiated(!chatInitiated)
        createChat()
    }

    useEffect(() => {
        // Send Audio Once Record Completed
        if (isAudioRecordedCompleted) {
            if (!chatId) {
                createChat(false, recorder.recordingBlob)
            } else {
                uploadAudio(recorder.recordingBlob, chatId);
            }
        }
    }, [recorder.recordingBlob]);

    const getMessagesList = (currentChatId: any) => {
        setLoading(true)
        setMessageList([]);
        axios
            .get(`${VS_PORTAL_API_URL}/messages/chats/${currentChatId}`, { headers: headers() })
            .then((response: {
                data: {
                    objects: any[]; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    response?.data?.objects?.map((messageItem: any) => {
                        messageItem.viewHelp = messageItem.reactions && messageItem.reactions.filter((reactionItem: any) => reactionItem.isHelpful === true).length
                        messageItem.viewNote = messageItem.notes?.length > 0
                    })
                    setMessageList(response?.data?.objects || []);
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const getSuggestionQuestionsList = () => {
        setLoading(true)
        setMessageList([]);
        let endpoint = `${VS_PORTAL_API_URL}/collections/${collectionId}`;
        if (localStorage.getItem('parentDocumentId')) {
            endpoint += `/documents/${localStorage.getItem('parentDocumentId')}/suggested_questions`;
        } else {
            endpoint += `/suggested_questions`;
        }
        axios
            .get(endpoint, { headers: headers() })
            .then((response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    setSuggestionQuestionList(response.data?.objects)
                    getDocumentsList();
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }


    const removeLinks = (text: any) => {
        let result = text.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');
        result = result.replace(/<a[^>]*>(.*?)<\/a>/g, '$1');
        result = result.replace(/\b\S+\.pdf\(Page \d+\)/g, '');
        result = result.replace(/https?:\/\/[^\s]+|www\.[^\s]+/g, '');
        result = result.replace(/\(\s*\)/g, '');
        result = result.replace(/,\s*,/g, ',');
        result = result.replace(/(,\s*)+$/g, '');
        return result.trim();
    };

    const copyToClipboard = (message: any) => {
        const plainText = removeLinks(message);
        navigator.clipboard.writeText(plainText).then(() => {
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const shareUrl = window.location.href;


    // eslint-disable-next-line no-empty-pattern
    const onClick: MenuProps['onClick'] = ({ }) => {
        //message.info(`Click on item ${key}`);
    };

    const items = [
        {
            key: '1',
            label: (
                <Space>
                    <EmailShareButton
                        url={shareUrl}
                        subject={sharedText}
                        body="body"
                    >
                        <EmailIcon size={25} round />
                        <span style={{ marginLeft: 10 }}>Email</span>
                    </EmailShareButton>
                </Space>
            ),
        },
        {
            key: '2',
            label: (
                <Space>
                    <TwitterShareButton
                        url={shareUrl}
                        title={sharedText}
                    >
                        <TwitterIcon size={25} round />
                        <span style={{ marginLeft: 10 }}>Twitter</span>
                    </TwitterShareButton>
                </Space>
            ),
        },
        {
            key: '3',
            label: (
                <Space>
                    <WhatsappShareButton
                        url={shareUrl}
                        title={sharedText}
                        separator=":: "
                    >
                        <WhatsappIcon size={25} round />
                        <span style={{ marginLeft: 10 }}>WhatsApp</span>
                    </WhatsappShareButton>
                </Space>
            ),
        },
    ];

    const updateChat = (isOutsideContext: boolean) => {
        axios.put(`${VS_PORTAL_API_URL}/chats/${chatId}`, {
            isOutsideContext
        }, { headers: headers() })
            .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
                if (!response.data.success) {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const uploadAudio = (audioBlob: any, currentChatId: any) => {
        const formdata = new FormData();
        const audioFile = new File([audioBlob], 'audio.wav');
        formdata.append('audio', audioFile, 'audio.wav');
        setLoading(true)
        axios
            .post(`${VS_PORTAL_API_URL}/messages/chats/${currentChatId}/audios`, formdata, { headers: { 'Authorization': getUserToken(), "Content-Type": "multipart/form-data" } })
            .then(async (response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    getMessagesList(currentChatId);
                    setLoading(false)
                    setIsAudioRecordedCompleted(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                    setIsAudioRecordedCompleted(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                    setIsAudioRecordedCompleted(false)
                }
            });
    }

    const updateMessageReaction = (isHelpful: boolean, isNotHelpful: boolean, messageId: number) => {
        axios.post(`${VS_PORTAL_API_URL}/messages/${messageId}/reactions`, {
            isHelpful,
            isNotHelpful
        }, { headers: headers() })
            .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
                if (response.data.success) {
                    getMessagesList(chatId);
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const updateMessageNotes = (messageId: number) => {
        setAutoScroll(false);
        setSaveMessageLoading(true)
        axios.post(`${VS_PORTAL_API_URL}/messages/${messageId}/notes`, {
            notes: messageNotes
        }, { headers: headers() })
            .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
                if (response.data.success) {
                    setMessageNotes('')
                    setSaveMessageLoading(false)
                    message.success('Notes Added Successfully')
                    getMessagesList(chatId);
                } else {
                    message.error(response?.data.message)
                    setSaveMessageLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setSaveMessageLoading(false)
                }
            });
    }


    const onChangeExternalMessage = (e: CheckboxChangeEvent) => {
        setIsOutsideContext(e.target.checked)
        updateChat(e.target.checked)
    };

    const checkPdfUrl = (message: any) => {
        return message.indexOf('pdf.voicesphere.co') !== -1;
    }


    const generateMessage = (message: any) => {
        if (isMobileBrowser) {
            return message.replace(/\`/g, '');
        } else {
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            return message.replace(urlRegex, function (url: string) {
                const urlSplit = url?.split('&documentId=');
                const pdfDocumentId = urlSplit[1]?.split('&page')[0];
                const replacedText = `${collectionId}?customIsOutsideContext=${customIsOutsideContext}#${pdfDocumentId}***`;
                return url.indexOf('&page=') !== -1 ? url?.replace('https://', '')
                    .replace(/pdf.voicesphere.co.*page=/g, replacedText)
                    .replace(/\`/g, '') : url.replace(/\`/g, '');
            })
        }
    }

    const handleViewNote = (messageItem: any) => {
        setAutoScroll(false);
        messageItem.viewNote = true;
        setMessageList((list) => [...list, { ...messageItem }].sort(function (a, b) { return b.id - a.id }));
    }

    const handleViewHelp = (messageItem: any) => {
        setAutoScroll(false);
        messageItem.viewHelp = true;
        updateMessageReaction(true, false, messageItem.id)
        setMessageList((list) => [...list, { ...messageItem }].sort(function (a, b) { return b.id - a.id }));
    }

    useEffect(() => {
        const scrollToBottom = () => {
            const scroll = bottomRef.current;
            if (scroll) {
                if (!getStorage('isFromRouter')) {
                    scroll.scrollTop = scroll.scrollHeight + window.innerHeight;
                } else {
                    scroll.scrollTop = scroll.scrollHeight
                }
            }
        };

        if (autoScroll) {
            setTimeout(scrollToBottom, 0); // Use setTimeout to ensure the DOM has updated
        }
    }, [messageList, messageSent, streamingMessage]); // Dependency array


    const adjustHeight = (element: HTMLTextAreaElement) => {
        element.style.height = 'auto';
        element.style.height = element.scrollHeight + 'px';
    };

    const goToPlugin = () => {
        const url = 'https://voicesphere.co'
        window.open(url, "_blank", "noreferrer");
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getPDFDocument = () => {
        removeStorage('pdfUrl')
        removeStorage('pdfFileName')
        setPdfViewerLoading(true)
        axios
            .get(`${VS_PORTAL_API_URL}/collections/${collectionId}/documents/${referenceDocumentId}`, { headers: headers() })
            .then((response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    setPdfDocumentResponse(response?.data?.objects || {});
                    if (response?.data?.objects) {
                        setStorage('pdfUrl', response?.data?.objects.url)
                        setStorage('pdfFileName', response?.data?.objects.name)
                    }
                    setPdfViewerLoading(false)
                } else {
                    message.error(response?.data.message)
                    setPdfViewerLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setPdfViewerLoading(false)
                }
            });
    }


    useEffect(() => {
        if (inputRef.current) {
            adjustHeight(inputRef.current);
        }
    }, [inputRef, formData.message]);

    useEffect(() => {
        if ((routeParams.collectionId !== getStorage('iframeRouterCollectionId')) && window.location.href.indexOf('/embed_doc/') != -1) {
            localStorage.removeItem('parentDocumentId')
            localStorage.removeItem('anonymousEmail')
            setLoadIframe(true);
            handleAnonymousLogin(styleId)
        } else {
            if (documentId && getUserToken() !== null && getUserToken() !== undefined) {
                setLoadIframe(true);
                handleAnonymousLogin(styleId)
            }
            else {
                if ((routeParams.collectionId === getStorage('iframeRouterCollectionId')) && window.location.href.indexOf('/embed_doc/') != -1 && (getStorage(`${getUserStorageType()}businessName`) == null || getStorage(`${getUserStorageType()}businessName`) == '' || getStorage(`${getUserStorageType()}businessName`) == undefined)) {
                    setBusinessData();
                }
            }
        }
    }, [routeParams.collectionId]);


    useEffect(() => {
        /** Set Dynamic Title for Embed Page **/
        if (getStorage(`${getUserStorageType()}businessName`) && getStorage(`${getUserStorageType()}collectionName`) && !getStorage('isFromRouter')) {
            document.title = `Chat with ${getStorage(`${getUserStorageType()}businessName`)} - ${getStorage(`${getUserStorageType()}collectionName`)} documents`;
        }
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderContentBasedOnFileType = (): any => {
        return (
            <AdobeViewer fileUrl={pdfDocumentResponse?.url} fileName={pdfDocumentResponse?.name} referencePageNumber={referencePageNumber} referenceDocumentId={referenceDocumentId} />
        );
    };

    const closeCurrentDocument = () => {
        setShowDocumentContainer(false)
        setReferencePageNumber('')
        setReferenceDocumentId('')
        setPdfDocumentResponse({})
        window.location.href = `${window.location.origin}/embed_doc/${collectionId}?customIsOutsideContext=${customIsOutsideContext}#`;
    }

    const verifyUserWithExternalApi = (phoneNumber: string) => {
        axios
            .get(`${LAWBUDDY_API_URL}?phoneNumber=${phoneNumber}`)
            .then((response: any) => {
                if (response.data.Status.isActive === true) {
                    setLoading(false)
                    setLoadIframe(false)
                } else {
                    // If the user is not active, prevent iframe loading
                    message.error("User verification failed.");
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate(`/accessDenied`);
                }
            }).catch((error) => {
                message.error("Failed to verify user.");
            });
    }

    const getCustomStyles = (customStyleId: any) => {
        setLoading(true)
        axios
            .get(`${VS_PORTAL_API_URL}/publics/styles/${customStyleId}`, { headers: headers() })
            .then(async (response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    setCustomStyles(response?.data?.objects || defaultStyles);
                    console.log(response?.data?.objects, 'objects')
                    if (getStorage('iframeRouterUserId') !== null && getStorage('iframeRouterUserId') !== undefined) {
                        setStorage(`${getIframeSessionVariable()}showMessageCounter`, response?.data?.objects?.showMessageCounter || false);
                        setStorage(`${getIframeSessionVariable()}showBusinessName`, response?.data?.objects?.showBusinessName || false);
                        setStorage(`${getIframeSessionVariable()}showBusinessLogo`, response?.data?.objects?.showBusinessLogo || false);
                        setStorage(`${getIframeSessionVariable()}showPoweredBy`, response?.data?.objects?.showPoweredBy || false);
                        setStorage(`${getIframeSessionVariable()}showWelcomeToOrganization`, response?.data?.objects?.showWelcomeToOrganization || false);

                        // Check if isLawBuddy is true
                        if (response?.data?.objects?.isLawBuddy === true) {
                            const email = localStorage.getItem('anonymousEmail')
                            if (email) {
                                const phoneNumber = email.split('@')[0];
                                await verifyUserWithExternalApi(phoneNumber);
                                setLoading(true)
                                setLoadIframe(true)
                            } else {
                                // Prevent iframe from loading if isLawBuddy is false
                                setLoading(false);
                                setLoadIframe(false)
                            }
                        } else {
                            setLoadIframe(false)
                        }
                    }
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const openDocumentPreview = (documentItem: any) => {
        if (documentItem.type === 'pdf') {
            window.location.href = `${window.location.origin}/embed_doc/${collectionId}?customIsOutsideContext=${customIsOutsideContext}#${documentItem.id}***1`;
        } else {
            window.open(documentItem.url)
        }
    }

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'key',
            width: '10%',
            className: 'firstColumn'
        },
        {
            title: 'Document',
            dataIndex: 'name',
            width: '90%',
            className: 'documentColumn',
            render: (name: any, record: any) => {
                return <Space>
                    <span style={{
                        cursor: 'pointer'
                    }}>{name}</span>
                </Space>
            }
        }
    ];

    const getDocumentsList = () => {
        setLoading(true)
        setDocumentsList([])
        axios
            .get(`${VS_PORTAL_API_URL}/collections/${collectionId}/documents`, { headers: headers() })
            .then((response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    if (response.data.objects) {
                        response.data.objects.documents.map((documentItem: any, index: number) => {
                            documentItem.key = index + 1;
                            return documentItem;
                        });
                        setDocumentsList(response.data.objects.documents)
                    }
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const getChatList = () => {
        setLoading(true)
        setChatList([])
        axios
            .get(`${VS_PORTAL_API_URL}/chats/${collectionId}`, { headers: headers() })
            .then((response: {
                data: {
                    objects: any[]; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    response.data.objects.map((chatItem: any) => {
                        chatItem.chatCreatedDate = moment(chatItem.createdAt).format('MM/DD/yyyy');
                    })
                    setChatList(response.data.objects)
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                    setLoading(false)
                }
            });
    }

    const handleDeleteConversations = () => {
        setLoading(true)
        axios
            .delete(`${VS_PORTAL_API_URL}/chats/collections/${collectionId}`, { headers: headers() })
            .then((response: {
                data: {
                    objects: any[]; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    message.success('Conversations Deleted Successfully')
                    getChatList();
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                    setLoading(false)
                }
            });
    }

    return (
        <>
            {loadIframe ? <div style={{ width: '100%', minHeight: 464, padding: 16 }}><Skeleton active paragraph={{ rows: 14 }} /></div> :
                <div className="flex-parent-element" style={{
                    'height': '100vh'
                }}>
                    {referenceDocumentId && referencePageNumber && !isMobileBrowser ?
                        <><Spin tip="Loading" size="large" spinning={pdfViewerLoading}></Spin><div className={documentsList.length ? "flex-child-element-width-document magenta" : "flex-child-element magenta"} ref={containerRef} style={{
                            'display': showDocumentContainer ? 'block' : 'none'
                        }}>
                            <CloseSquareOutlined style={{
                                float: 'right'
                            }} onClick={() => closeCurrentDocument()} />
                            {pdfDocumentResponse && pdfDocumentResponse?.url && renderContentBasedOnFileType()
                                // eslint-disable-next-line react/no-unknown-property
                            }
                            <div id="pdf-div" className="full-window-div"></div>
                        </div></>
                        :
                        (documentsList.length > 1 && !isMobileBrowser) ? <div className="flex-child-element-width-document magenta" ref={containerRef} style={{
                            'height': tableHeight,
                            overflowY: 'scroll',
                            backgroundColor: `${customStyles.chatBackgroundColor}`
                        }}>
                            <Table columns={columns} dataSource={documentsList} loading={loading} style={{ cursor: 'pointer' }} onRow={(record: any) => {
                                return { onClick: () => openDocumentPreview(record) };
                            }} />
                        </div> : ''
                    }
                    <div className="flex-child-element green">
                        <div className="iframe-container">
                            <div className="modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="msg-head">
                                        <div className="row">
                                            {!getStorage('isFromRouter') &&
                                                <div className={isMobileBrowser ? "nc-top m-auto iframe-header-mobile" : "nc-top m-auto iframe-header"} style={{
                                                    backgroundColor: `${customStyles.headerBackgroundColor}`
                                                }}>
                                                    <Row>
                                                        {showBusinessName ? (
                                                            <><Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                                {businessImageUrl && businessImageUrl !== "null" ? (
                                                                    <img
                                                                        src={businessImageUrl}
                                                                        className="rounded-circle"
                                                                        alt={altText}
                                                                        title="Organization"
                                                                        style={imageStyle} />
                                                                ) : (
                                                                    <div
                                                                        className="rounded-circle d-flex align-items-center justify-content-center"
                                                                        style={imageStyle}
                                                                    >
                                                                        <span className="first-letter">{businessName?.charAt(0)?.toUpperCase()}</span>
                                                                    </div>
                                                                )}
                                                                <Space direction="vertical" size="small" style={{ lineHeight: '1', marginLeft: '16px', paddingTop: '0px' }}>
                                                                    <div style={{ fontSize: '24px', fontWeight: 'bold', textTransform: 'capitalize', color: `${customStyles.headerBusinessTextColor}` }}>
                                                                        {businessName}
                                                                    </div>
                                                                </Space>
                                                            </Col><Col span={11}></Col><Col span={1} style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
                                                                    <ReloadOutlined title="Refresh" onClick={() => handleNewChat()} style={{
                                                                        color: `${customStyles.headerTextColor}`
                                                                    }} />
                                                                </Col>

                                                            </>
                                                        ) : (
                                                            <>
                                                                <Col span={23}></Col>
                                                                <Col span={1} style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
                                                                    <ReloadOutlined title="Refresh" onClick={() => handleNewChat()} style={{
                                                                        color: `${customStyles.headerTextColor}`
                                                                    }} />
                                                                </Col></>
                                                        )}
                                                    </Row>

                                                </div>
                                            }

                                            <a style={{
                                                'display': 'none'
                                            }}
                                                id="newChat" onClick={() => handleNewChat()}>New Chat</a>
                                        </div>
                                    </div>
                                    <Spin tip="Loading" size="large" spinning={loading}></Spin>
                                    <div id="chatContainer" className="modal-body over-hide">
                                    {documentsList.length > 1 && !isMobileBrowser ?
                                    <div className="chatlist">
                                        <Chatlist data={chatList} getMessage={(id) => {
                                            setChatId(id);
                                            setMessageLoading(false);
                                            socket.emit("joinChat", id);
                                        }} handleDeleteConversations={handleDeleteConversations} key="chatlist-containter"></Chatlist>
                                    </div> : ''
                                    }
                                        <div className="msg-body" style={{
                                            'height': windowHeight,
                                            overflowY: 'scroll',
                                            backgroundColor: `${customStyles.chatBackgroundColor}`
                                        }} ref={bottomRef}>
                                            {showMessageCounter ?
                                                <div style={{ float: 'right', marginRight: 24, marginTop: 24 }}>
                                                    <h6 style={{ color: `${customStyles.chatTitleColor}` }}>Count for the day : {messsageCount}</h6>
                                                </div>
                                                : ''
                                            }
                                            <ul className="d-flex flex-column m-auto flex-column-reverse" style={{
                                                'width': '98%'
                                            }}>

                                                {!messageList?.length &&
                                                    <li>
                                                        <div className="msg-body">

                                                            <div className="col-12 Ask-your-first-question d-flex  flex-column justify-content-center align-items-center">
                                                                {
                                                                    getStorage('isFromRouter') &&
                                                                    <img src={BlankChatIcon} alt="Chat" title="Chat Icon" />
                                                                }

                                                                {showWelcomeToOrganization && (
                                                                    <>
                                                                        <h6 style={{ color: `${customStyles.chatTitleColor}` }}>
                                                                            Welcome to {businessName}
                                                                        </h6>
                                                                        <p style={{ color: `${customStyles.chatSubTitleColor}` }}>
                                                                            Start by clicking our popular questions or just type your question below.
                                                                        </p>
                                                                    </>
                                                                )}<div>
                                                                    {showWelcomeToOrganization &&
                                                                        suggestionQuestionList?.map((question, index: any) => {
                                                                            return (
                                                                                <p
                                                                                    style={{
                                                                                        textAlign: 'left',
                                                                                        fontStyle: 'italic',
                                                                                        cursor: 'pointer',
                                                                                        backgroundColor: `${customStyles.suggestedQuestionBackgroundColor}`,
                                                                                        border: `1px solid ${customStyles.suggestedQuestionBorderColor}`,
                                                                                        color: `${customStyles.suggestedQuestionTextColor}`,
                                                                                    }}
                                                                                    className="question-item"
                                                                                    onClick={() => sendMessage(question)}
                                                                                    key={`suggestion-question-${index}`}
                                                                                >
                                                                                    {question}
                                                                                </p>
                                                                            );
                                                                        })}
                                                                </div>

                                                                {showBusinessLogo ?
                                                                    <Space onClick={goToPlugin} style={{
                                                                        cursor: 'pointer',
                                                                        display: 'flex',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        {showWelcomeToOrganization && (
                                                                            <img
                                                                                src={poweredByVoiceSphereImgPath}
                                                                                alt="logo"
                                                                                title="VoiceSphere"
                                                                                style={{ width: '250px' }}
                                                                            />
                                                                        )}

                                                                    </Space>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </li>}
                                                {streamingMessage && !!streamingMessage.length &&
                                                    <li>
                                                        <div className="repaly-box">
                                                            {streamingMessage}
                                                        </div>
                                                    </li>}
                                                {_.uniqWith(messageList, (arrVal: any, othVal: any) => arrVal.id === othVal.id)?.filter((item: any) => !!item.id)
                                                    .map((item: any, index: any) => {
                                                        if (!item.replyToId) {
                                                            return (<li className="sender" key={`sender-${index}`}>
                                                                <div className="d-flex flex-row flex-row-reverse justify-content-end">
                                                                    <img src={profileImage}
                                                                        alt="avatar 1"
                                                                        style={{
                                                                            'width': '32px',
                                                                            'height': '32px',
                                                                            'marginRight': '17px'
                                                                        }} />
                                                                    <div className="text-wrap">
                                                                        <p className="small p-3 me-3 mb-1 chat-answer-font-size" style={{
                                                                            color: `${customStyles.chatQuestionTextColor}`,
                                                                            backgroundColor: `${customStyles.chatQuestionBackgroundColor}`
                                                                        }}>{item.message}</p>
                                                                    </div>
                                                                </div>
                                                            </li>);
                                                        }
                                                        if (item.replyToId) {
                                                            return (<li className="repaly" key={`replay-${index}`}>
                                                                <div className="d-flex flex-row justify-content-end">
                                                                    <img src={businessImageUrl && businessImageUrl !== "null" ? businessImageUrl : vs_logo}
                                                                        alt="avatar 1" style={{
                                                                            'width': '32px',
                                                                            'height': '32px'
                                                                        }} />
                                                                    <div className="repaly-box" style={{
                                                                        color: `${customStyles.chatAnswerTextColor}`,
                                                                        backgroundColor: `${customStyles.chatAnswerBackgroundColor}`
                                                                    }}>
                                                                        {
                                                                            checkPdfUrl(item.message) && !isMobileBrowser ?
                                                                                <ReactMarkdown
                                                                                    //linkTarget="_blank"
                                                                                    components={{
                                                                                        img: (props: any) => (
                                                                                            <Image src={props.src} alt={props.alt} className="reply-message-img" />
                                                                                        ),
                                                                                    }}
                                                                                >
                                                                                    {generateMessage(item.message)}
                                                                                </ReactMarkdown>
                                                                                :
                                                                                <ReactMarkdown
                                                                                    linkTarget="_blank"
                                                                                    components={{
                                                                                        img: (props: any) => (
                                                                                            <Image src={props.src} alt={props.alt} className="reply-message-img" />
                                                                                        ),
                                                                                    }}
                                                                                >
                                                                                    {generateMessage(item.message)}
                                                                                </ReactMarkdown>
                                                                        }
                                                                        <Space style={{ float: 'right' }}>
                                                                            <Popover placement="topRight" destroyTooltipOnHide={true} content={
                                                                                <>
                                                                                    <div className="notes-list">
                                                                                        {
                                                                                            item.notes?.map((subitem: any, index: any) => {
                                                                                                return (
                                                                                                    <><Row>
                                                                                                        <Col span={24}><span>{subitem.notes}</span></Col>
                                                                                                    </Row>
                                                                                                        <Row className={index < item.notes.length - 1 ? 'today' : 'today today-border-none'}>
                                                                                                            <Col span={12}></Col>
                                                                                                            <Col span={12} style={{
                                                                                                                textAlign: 'end'
                                                                                                            }}>{subitem.updatedAt ? moment(subitem.updatedAt).format('MMM DD, YYYY') : ''}</Col>
                                                                                                        </Row></>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    <Form layout="vertical" hideRequiredMark>
                                                                                        <Row gutter={16}>
                                                                                            <Col span={24}>
                                                                                                <Input.TextArea rows={4} cols={50} placeholder="Type a note" style={{
                                                                                                    background: '#f5f5f5',
                                                                                                    resize: 'none',
                                                                                                    marginBottom: 8
                                                                                                }} onChange={(e) => setMessageNotes(e.target.value)} />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row gutter={16}>
                                                                                            <Col span={12}></Col>
                                                                                            <Col span={12} style={{
                                                                                                textAlign: 'right'
                                                                                            }}><Button type="primary" style={{
                                                                                                background: '#758AF0'
                                                                                            }} onClick={() => updateMessageNotes(item.id)} loading={saveMessageLoading}>Add</Button></Col>
                                                                                        </Row>
                                                                                    </Form>
                                                                                </>
                                                                            } trigger="click">
                                                                                {item?.notes?.length > 0 ?
                                                                                    <Button type="primary" className="chat-list-btn">
                                                                                        <Space>
                                                                                            <img className="chat-list-icon-img" src={noteWhiteImgPath} alt="View note" style={{ cursor: 'pointer', width: 18, height: 18 }} title="View note" onClick={() => {
                                                                                                copyToClipboard(item.message)
                                                                                            }} />
                                                                                            <span>View note</span>
                                                                                        </Space>
                                                                                    </Button>
                                                                                    :
                                                                                    getStorage('isFromRouter') &&
                                                                                    <img src={noteSmallImgPath} alt="Note" style={{ cursor: 'pointer', width: 24, height: 24 }} title={'Add note'} onClick={() => {
                                                                                        handleViewNote(item)
                                                                                    }} />
                                                                                }
                                                                            </Popover>
                                                                        </Space>
                                                                    </div>
                                                                    <Space direction="vertical">
                                                                        <img src={CopyIcon} alt="Copy" style={{ cursor: 'pointer' }} title="Copy Message" onClick={() => copyToClipboard(item.message)} />
                                                                        <Dropdown menu={{ items, onClick }} placement="bottomLeft">
                                                                            <img onClick={(e) => {
                                                                                setSharedText(item.message);
                                                                                e.preventDefault();
                                                                            }} src={ShareIcon} alt="Share" style={{ cursor: 'pointer' }} title="Share Message" />
                                                                        </Dropdown>
                                                                        {!item.viewHelp ? <Button type="primary" shape="circle" style={{
                                                                            right: 2,
                                                                            backgroundColor: 'white'
                                                                        }} onClick={() => handleViewHelp(item)}>
                                                                            <Space>
                                                                                <img src={likeImgPath} alt="Help" style={{ cursor: 'pointer', width: 18, height: 18 }} title="Helpful" />
                                                                            </Space>
                                                                        </Button>
                                                                            :
                                                                            <Button type="primary" shape="circle" style={{
                                                                                right: 2
                                                                            }} onClick={() => copyToClipboard(item.message)}>
                                                                                <Space>
                                                                                    <img src={helpfulWhiteImgPath} alt="Help" style={{ cursor: 'pointer', width: 18, height: 18 }} title="Helpful" />
                                                                                </Space>
                                                                            </Button>
                                                                        }
                                                                        { }
                                                                    </Space>
                                                                </div>
                                                            </li>);
                                                        }
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                    {messageLoading &&
                                        <Loader></Loader>}
                                    <>
                                    </>
                                </div>
                            </div>
                            <div className={footerClassName}>
                                <div className="input-chat-mic bg-white p-3 d-flex m-auto" style={{
                                    width: '100%'
                                }}>
                                    {recorder.isRecording &&
                                        <input type="image" src={playImgPath} alt="Submit" width="100" height="44" onClick={() => {
                                            recorder.stopRecording();
                                            setIsAudioRecordedCompleted(true);
                                        }}></input>}
                                    {!recorder.isRecording &&
                                        <input type="image" src={micImgPath} alt="Submit" width="100" height="44" onClick={() => {
                                            recorder.startRecording();
                                            setIsAudioRecordedCompleted(false);
                                        }}></input>}
                                    {recorder.isRecording &&
                                        <div style={{ width: '100%' }}>
                                            <AudioRecorder
                                                audioTrackConstraints={{
                                                    noiseSuppression: true,
                                                    echoCancellation: true,
                                                }}
                                                recorderControls={recorder}
                                                showVisualizer={true} />
                                        </div>}
                                    <div style={{ display: recorder.isRecording ? 'none' : 'block', width: '100%' }}>
                                        <textarea
                                            ref={inputRef}
                                            value={formData.message}
                                            className="form-control mic-text-input"
                                            placeholder="Type your question"
                                            onChange={(event) => onChange('message', event.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" && !e.shiftKey) {
                                                    e.preventDefault();
                                                    sendMessage();
                                                }
                                            }}
                                            style={{
                                                height: '44px'
                                            }}
                                        />
                                    </div>

                                    <span className="btn-submit" style={{ display: recorder.isRecording ? 'none' : 'block', cursor: 'pointer', left: 12 }} onClick={() => { sendMessage(); }}>
                                        <input type="button" className="btn send-btn" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default IframeChat;
